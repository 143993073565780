<template>
    <v-container style="background:#f2f2f2; min-height:100vh;">
        <v-row class="py-0" style="background:white;">
            <v-col cols="3">
                <v-btn @click="atras" text style="text-transform: capitalize;">
                    <v-icon> mdi-chevron-left</v-icon> Atrás
                </v-btn>
            </v-col>
            <v-col cols="6" class="py-4" style="text-align:center; font-size:18px;">
                Tu Pedido
            </v-col>
        </v-row>

        <div v-if="sucursal.status=='activo'">
            <v-form v-if="switch1==false" class="px-5 pt-6 mb-4 mt-6" style="background:white;">
                <v-icon class="mr-2"> mdi-map-marker</v-icon>Dirección de Entrega
                <v-row class="pt-4">
                    <v-col cols="8">
                        <v-text-field
                            v-model="pedido.direccion.calle" 
                            :rules="[rules.required]"
                            name="calle"
                            label="Calle"
                            outlined
                            rounded
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field 
                            v-model="pedido.direccion.numero" 
                            :rules="[rules.required]"
                            name="numero"
                            label="#"
                            outlined
                            rounded
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-autocomplete 
                    v-model="pedido.direccion.colonia" 
                    :items="colonias"
                    item-text="nombre" 
                    item-value="id"
                    :rules="[rules.required]"
                    name="colonia"
                    label="Colonia"
                    outlined
                    rounded
                ></v-autocomplete>
            </v-form>

            
            <v-switch
            class="ml-6"
                v-model="switch1"
                label="Recoger en sucursal"
                ></v-switch>

            <v-card elevation="0" class="mt-2">
                <v-row class="py-4 px-7">
                    <v-icon class="mr-2"> mdi-clock-outline</v-icon> Entrega
                    <v-spacer></v-spacer>
                    <span v-if="colonia!=undefined">
                        {{colonia.tiempo_de_entrega}} minutos aprox.
                    </span>
                    <span v-else>
                        Seleccióne dirección
                    </span>
                </v-row>
            </v-card>

            

            <v-form class="px-5 pt-6 mb-4 mt-6" style="background:white;">
                <v-icon class="mr-2"> mdi mdi-account</v-icon>Datos de Contacto
                <div class="pt-4">
                   
                        <v-text-field
                            v-model="pedido.nombre" 
                            :rules="[rules.required]"
                            name="nombre"
                            label="Nombre"
                            outlined
                            rounded
                        ></v-text-field>
                        <v-text-field
                            v-model="pedido.telefono" 
                            :rules="[rules.required]"
                            name="telefono"
                            label="Telefono"
                            type="number"
                            outlined
                            rounded
                        ></v-text-field>

                </div>


            </v-form>

            <v-card elevation="0" class="mt-4 pt-4 pb-0 px-7">
                    Método de Pago
                    <v-radio-group v-model="pedido.metodo" column>
                        <v-radio
                            label="Efectivo"
                            value="Efectivo"
                        ></v-radio>
                        <v-radio
                            label="Tarjeta (terminal)"
                            value="Tarjeta"
                        ></v-radio>
                    </v-radio-group>
            </v-card>

            <v-card elevation="0" class="mt-4">
                <v-row class="pt-4 pb-2 px-7">
                    <v-col cols="4" class="py-4">
                        Propina
                    </v-col>
                    <v-col cols="8" class="pa-0">
                        <v-text-field 
                        class="perro"
                         append-icon="mdi-currency-usd"
                         v-model="pedido.propina" 
                            name="monto"
                            type="number"
                            label="Monto"
                            outlined
                            rounded
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card>

            <v-card elevation="0" class="mt-4 pt-4 px-7">
                Resumen
                <div class="pt-4 pb-3 px-7 mb-12">
                    <div>
                    Costo de Productos: 
                        <span style="float: right;" v-if="total!=undefined">{{total.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}</span>
                        <span style="float: right;" v-else>$0.00</span>
                    </div>

                    <div>
                    Costo de Envío: 
                        <span style="float: right;" v-if="colonia!=undefined">{{colonia.valor.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}</span>
                        <span style="float: right;" v-else>$0.00</span>
                    </div>

                    <div>
                    Tarifa por Servicio:
                        <span style="float: right;">{{comision.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}</span>
                    </div>

                    <div>
                    Propina: 
                        <span style="float: right;">{{(pedido.propina*1).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}</span>
                    </div>

                    <div>
                    <strong>Total: 
                        <span style="float: right;">{{totalGeneral.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}</span></strong>
                    </div>
                </div>
            </v-card>
            <div style="height:20px;"/>
        </div>

        <!-- cerrado -->
        <div style="filter:opacity(0.21);" v-else>
            <v-img max-width="50vw" style="margin:25vw 25vw 30px 25vw;" src="https://tacoselguero.mx/wp-content/uploads/2021/05/close.png"></v-img>
            <strong style="display: table; font-size: 25px; margin: auto;">La sucursal ya cerró</strong>
        </div>

        <div v-if="sucursal.status=='activo'" style="z-index: 9; position: fixed; left: 50%; bottom: -10px; transform: translate(-50%, -50%); margin: 0 auto; width:90%; border-radius: 30px!important; background: #001e7a;" @click="save">
            <v-row class="text-center px-4 py-3" style="color:white;">
                <v-col style="cursor:pointer;" cols="6">
                    <a style="color:white; padding:5px 10px; border-radius:5px;"> Realizar pedido </a>
                </v-col>
                <v-col style="cursor:pointer;" cols="6">
                    {{totalGeneral.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}
                </v-col>
            </v-row>
        </div>

        <div v-else style="filter:opacity(0.21); z-index: 9; position: fixed; left: 50%; bottom: -10px; transform: translate(-50%, -50%); margin: 0 auto; width:90%; border-radius: 30px!important; background: #001e7a;">
            <v-row class="text-center px-4 py-3" style="color:white;">
                <v-col style="cursor:pointer;" cols="6">
                    <a style="color:white; padding:5px 10px; border-radius:5px;"> Realizar pedido </a>
                </v-col>
                <v-col style="cursor:pointer;" cols="6">
                    {{totalGeneral.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script>

import axios from "axios"
export default {
    data () {
        return {
            switch1:false,
            pedido:{
                envio:0,
                entrega:'',
                nombre:'',
                telefono:'',
                status:'en curso',
                direccion:{
                    calle:'',
                    numero:'',
                    colonia:'',
                },
                sucursal: localStorage.getItem("sucursal"),
                total:'', 
                platos:'',
                propina:0,
                status:'nuevo',

            },
            rules: {
                required: value => !!value || 'Campo requerido.'
            },
        }
    },
    methods:{
        nombreColonia(id){
            return this.colonias.filter(colonia=>colonia.id == id).map(colonia=>colonia.nombre)[0]
        },
        atras(){
            window.history.back();
        
        },
        save(){
            this.pedido.total= this.totalGeneral
            this.pedido.platos= this.carrito
            
            this.pedido.status= 'en curso'

            if(this.switch1==true){
                this.pedido.entrega = 'pick up'
            }else{
                this.pedido.entrega= this.pedido.direccion.calle+', '+this.pedido.direccion.numero+', '+ this.nombreColonia(this.pedido.direccion.colonia)
            }


            axios.post('https://torstdio.site/api/v1/pedido/guardar',Object.assign(this.pedido)).then(resp => {
                localStorage.setItem("pedido", resp.data.id);
                window.location.replace("#/gracias");
            })
        }
    },
    computed:{
        comision(){
            var sum = 0 + this.total*.16 + this.pedido.propina*.16
            if(this.pedido.metodo=='Tarjeta'){
                if(this.colonia!=null && this.colonia!=undefined && this.colonia!=''){
                    sum = sum + this.colonia.valor*.16
                }
                return sum
            }else{
                return 0
            }
        },
        totalGeneral(){
            var sum = 0 + this.total + (this.pedido.propina*1)
            if(this.pedido.metodo=='Tarjeta'){
                if(this.colonia!=null && this.colonia!=undefined && this.colonia!=''){
                    sum = sum + this.colonia.valor
                }
                return sum + this.comision
            }else{
                return sum
            }
        },
        carrito(){
            return this.$store.state.carrito.carritos
        },
        colonias(){
            return this.$store.state.colonia.colonias.filter(colonia=>colonia.sucursal == localStorage.getItem("sucursal"))
        },
        colonia(){
            return this.colonias.filter(colonia=>colonia.id==this.pedido.direccion.colonia)[0]
        },
        total(){
            var perro = this.$store.state.carrito.carritos
            if(perro!=undefined){
                var sum = 0
                for(var i=0; i<perro.length; i++){
                        sum = sum + perro[i].total
                }
                return sum + this.pedido.envio
            }else{
                return 0
            }
        },
        sucursal(){
            return this.$store.state.sucursal.sucursales.filter(sucursal=>sucursal.id == localStorage.getItem("sucursal"))[0]
        }
    },
    created(){
        this.$store.dispatch('colonia/getColonias') 
        this.$store.dispatch('sucursal/getSucursales')
    },
    mounted(){
      Echo.channel('test')
          .listen('Test', (e) => {
            this.$store.dispatch('sucursal/getSucursales')
          })
    },
}
</script>

<style>
.perro .v-text-field__details{
    display:none;
}
</style>